import { ContentType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import { ContentData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import params from '~/params/content.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageEspaceContentUpdate: FC<PageProps & UserProps & EspaceProps> = ({
  espace,
  user,
  params: { contentId, espaceId, parentCollectionName, parentId },
}) => {
  const contentData = new ContentData({
    espaceId,
    params,
    parentCollectionName,
    parentId,
  });

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <Form<ContentType>
          displayAdd
          displayNavigation={false}
          displayView={false}
          docId={contentId}
          itemPathnamePrefix={`/espaces/${espaceId}/contents/${parentCollectionName}/${parentId}/${contentId}/`}
          model={contentData}
          name="content"
          parentCollectionName={parentCollectionName}
          parentId={parentId}
          type="update"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageEspaceContentUpdate);
